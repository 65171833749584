import "./footer.scss";

const Footer: React.FC = () => {
    return (
        <>
            <div className='contain-wrapper green'>
                <div className='footer-wrapper contain'>
                    <div className='contact'>
                        <h2>CONTACT</h2>
                    </div>
                    <div className='company-wrapper'>
                        <div className='company'>
                            <div className='footer-logo'>
                                <img
                                    src='../images/kontor.svg'
                                    alt='Kontor logo'
                                />
                            </div>
                            <div className='agents'>
                                <div className='agent'>
                                    <p>ANDREW GIBSON</p>
                                    <a href='mailto:andrew@kontor.com'>
                                        <p>andrew@kontor.com</p>
                                    </a>
                                    <p>07866 602 381 </p>
                                </div>
                                <div className='agent'>
                                    <p>PAUL GOLD</p>
                                    <a href='mailto:paul@kontor.com'>
                                        <p>paul@kontor.com</p>
                                    </a>
                                    <p>07779 100 875</p>
                                </div>
                            </div>
                        </div>
                        <div className='company'>
                            <div className='footer-logo'>
                                <img
                                    src='../images/kf.png'
                                    alt='Knight frank logo'
                                />
                            </div>
                            <div className='agents'>
                                <div className='agent'>
                                    <p>SERENA HARWOOD</p>
                                    <a href='mailto:serena.harwood@knightfrank.com'>
                                        <p>serena.harwood@knightfrank.com</p>
                                    </a>
                                    <p>07790 344 116</p>
                                </div>
                                <div className='agent'>
                                    <p>LYDIA CARTER</p>
                                    <a href='mailto:lydia.carter@knightfrank.com'>
                                        <p>lydia.carter@knightfrank.com</p>
                                    </a>
                                    <p>07929 668 005</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='copyright'>
                        <p>©2024. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
